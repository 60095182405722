import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getEventsByTime } from '@/utils/compareEventsFunc'

import TemplateController from '../category-page-template/template-controller'
import EventsView from './events-view'

const slug = 'events'

const EventsController = ({ pageContext, location }) => {
  const originArticles = pageContext.articles

  const { i18n } = useTranslation()
  const lang = i18n.language
  const viewProps = TemplateController({
    data: { articles: { edges: originArticles } },
    location,
    slug,
    lang,
  })
  const { allArticles, articles } = viewProps

  const newEvents = useMemo(
    () => getEventsByTime(allArticles, false),
    [allArticles]
  )
  const [oldEvents, setOldEvents] = useState(getEventsByTime(articles, true))
  useEffect(() => {
    setOldEvents(getEventsByTime(articles, true))
  }, [articles])

  return (
    <EventsView
      {...viewProps}
      newEvents={newEvents}
      oldEvents={oldEvents}
      slug={slug}
    />
  )
}

export default EventsController
