import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { getTrendingPost } from '@/helpers/get-trending-posts'

const TemplateController = ({ data, location, slug, lang }) => {
  const allArticles = useMemo(() => _.get(data, 'articles.edges'), [data])
  const trendingArticles = useMemo(
    () => getTrendingPost(allArticles),
    [allArticles]
  )

  const [selectedCategories, setSelectedCategories] = useState({})
  const [allFilteredArticles, setAllFilteredArticles] = useState(allArticles)

  const handleFilter = useCallback(
    (cats) => {
      setSelectedCategories({ ...cats })
      const selectedCatKeys = Object.values(cats).filter((c) => !!c)
      if (selectedCatKeys.length > 0) {
        const result = _.filter(allArticles, (article) => {
          const filterRes = _.map(
            _.get(article, 'node.categories.nodes'),
            (cat) => _.includes(selectedCatKeys, cat.description)
          )
          const filterPassCount = _.countBy(filterRes, (res) =>
            res ? 'pass' : 'fail'
          )
          return filterPassCount.pass === selectedCatKeys.length
        })

        setAllFilteredArticles(result)
      } else {
        setAllFilteredArticles(allArticles)
      }
    },
    [allArticles]
  )

  useEffect(() => {
    const cat = _.get(location, 'state.filter', undefined)
    if (cat) {
      if (selectedCategories[slug] !== cat) {
        setSelectedCategories({ [slug]: cat })
        handleFilter({ [slug]: cat })
      }
      document.getElementById('topic-filter-location')?.scrollIntoView()
    }
  }, [location, selectedCategories, slug, handleFilter])

  return {
    trendingArticles,
    allArticles,
    articles: allFilteredArticles,
    selectedCategories,
    handleFilter,
  }
}

export default TemplateController
