import _ from 'lodash'
import moment from 'moment'

export const getEventsByTime = (articles, past = true) => {
  const isPast = (datetime) => {
    return moment().unix() > moment(datetime, 'DD/MM/YYYY').unix()
  }
  return articles.filter((e) => {
    if (_.get(e, 'node.acf.eventDatetime.endDate')) {
      const isPastRes = isPast(_.get(e, 'node.acf.eventDatetime.endDate'))
      return past ? isPastRes : !isPastRes
    }
    if (_.get(e, 'node.acf.eventDatetime.startDate')) {
      const isPastRes = isPast(_.get(e, 'node.acf.eventDatetime.startDate'))
      return past ? isPastRes : !isPastRes
    }
    return false
  })
}

export const getEventsByRelatedTag = (curPost, posts, tags, categories) => {
  return _.filter(posts, (post) => {
    let isRelated = false
    if (post.node.tags && categories) {
      if (
        !_.isEmpty(
          post.node.tags.nodes.filter((t) =>
            tags.some((t2) => _.toLower(t.name) === _.toLower(t2.name))
          )
        )
      ) {
        isRelated = true
      }
    }
    return isRelated && post.node.id !== curPost.id
  })
}

export const getEventsByRelatedCat = (curPost, posts, categories) => {
  return _.filter(posts, (post) => {
    let isRelated = false
    if (post.node.categories && categories) {
      if (
        post.node.categories.nodes.filter((t) =>
          categories.some((t2) => t2.slug === t.slug)
        ).length > 1
      ) {
        isRelated = true
      }
    }
    return isRelated && post.node.id !== curPost.id
  })
}
