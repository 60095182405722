import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { eventsLanguages } from '@/i18n/config'
import { getBrowserPrefLang } from '@/modules/utils'

import EventsController from '../screens/events'

const Events = (props) => {
  const { i18n } = useTranslation()
  useEffect(() => {
    const allAvaLangs = eventsLanguages.map((lang) => lang.code)
    const lang = getBrowserPrefLang(allAvaLangs)
    if (lang && lang !== i18n.language) {
      const path =
        eventsLanguages.find((item) => item.code === lang)?.path ?? ''
      navigate(`/events${path}`)
    }
  }, [i18n.language])
  return <EventsController {...props} />
}

export default Events
