import React from 'react'

export const FilterHashLocation = () => (
  <div
    id="topic-filter-location"
    style={{
      display: 'block',
      position: 'relative',
      top: '-70px',
      visibility: 'hidden',
    }}
  />
)
