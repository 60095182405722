import './events-style.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FilterHashLocation } from '@/components/filters/filter-hash-location'

import {
  AdsSection,
  CardList,
  FilterMobile,
  Filters,
  Seo,
  Title,
} from '../../components'

const EventsView = ({
  slug,
  newEvents,
  oldEvents,
  selectedCategories,
  handleFilter,
}) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Seo
        title={t('events.meta.title')}
        description={t('events.meta.description')}
        link="/events"
        lang={i18n.language}
      />
      <FilterMobile
        type={slug}
        title="topics"
        onClick={handleFilter}
        selectedCategories={selectedCategories}
      />
      <div className="events-container has-mobile-filter">
        <Title title={t('events.upcoming')} />
        <CardList
          data={newEvents}
          hasMore={false}
          shouldLimitShowNumber={false}
          isEvent
        />
        <FilterHashLocation />
        <div className="all-article-box">
          <Title title={t('events.past')} />
          <Filters
            type={slug}
            title="types"
            onClick={handleFilter}
            selectedCategories={selectedCategories}
          />
          <CardList data={oldEvents} isEvent />
        </div>
        <AdsSection />
      </div>
    </div>
  )
}
export default EventsView
