const regions = ['us', 'eea', 'br', 'sg', 'au', 'tr', 'fr']

export const getRegionPath = (path: string) => {
  const region = regions.find((region) => path.match(new RegExp(`^/${region}`)))
  return region ?? ''
}

export const i18nLanguageToDisplayString = (language: string) => {
  switch (language) {
    case 'da':
      return 'Dansk'
    // case "de":
    //   return "Deutsch"
    case 'en':
      return 'English'
    case 'es':
      return 'Español'
    case 'fr':
      return 'Français'
    case 'hu':
      return 'Magyar'
    case 'id':
      return 'Bahasa Indonesia'
    case 'it':
      return 'Italiano'
    case 'pl':
      return 'Polski'
    case 'pt':
      return 'Português'
    case 'pt-BR':
      return 'Português'
    case 'ro':
      return 'Română'
    case 'ru':
      return 'Pусский'
    case 'tr':
      return 'Türkçe'
    case 'vi':
      return 'Tiếng Việt'
    case 'zh-TW':
      return '繁體中文'
    default:
      return ''
  }
}

export const getBrowserPrefLang = (langs: string[]) => {
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        let lang = navigator.languages[i]
        if (lang !== 'pt-BR') {
          lang = lang.split('-')[0]
        }

        if (langs.includes(lang)) {
          return lang
        }
      }
    }
    if (navigator.userLanguage) {
      let lang = navigator.userLanguage
      if (lang !== 'pt-BR') {
        lang = lang.split('-')[0]
      }
      if (langs.includes(lang)) {
        return lang
      }
    }
    if (navigator.language) {
      let lang = navigator.language
      if (lang !== 'pt-BR') {
        lang = lang.split('-')[0]
      }
      if (langs.includes(lang)) {
        return lang
      }
    }
    return null
  }

  return found.length > 0 ? found : undefined
}

export const tryConvertWordpressSrc = (src) => {
  if (
    src &&
    src.startsWith(`${process.env.CONTENT_HUB_ENDPOINT}/wp-content/uploads`)
  ) {
    return src.replace(
      `${process.env.CONTENT_HUB_ENDPOINT}/wp-content/uploads`,
      `${process.env.GATSBY_WORDPRESS_CACHE_BASE}/wp_media`
    )
  }
  return src
}
